import mixpanel, { track } from 'mixpanel-browser';

export enum mixpanelEventNames {
  DOC_CAT_APPROVE_DOCUMENT_CATEGORY = 'DOC_CAT_APPROVE_DOCUMENT_CATEGORY',
  CHECKLIST_REPORT_RULE_ERROR = 'CHECKLIST_REPORT_RULE_ERROR',
  CHECKLIST_APPROVE_RULE = 'CHECKLIST_APPROVE_RULE',
  CHECKLIST_DOCUMENT_CATEGORIZATION_ERROR = 'CHECKLIST_DOCUMENT_CATEGORIZATION_ERROR',
  CHECKLIST_DURATION = 'CHECKLIST_DURATION',
  CHECKLIST_DURATION_BY_CATEGORY = 'CHECKLIST_DURATION_BY_CATEGORY',
  DOC_CAT_DURATION_REVIEW = 'DOC_CAT_DURATION_REVIEW',
  ALL_LOANS_DURATION = 'ALL_LOANS_DURATION',
  DOC_SWAP = 'DOC_SWAP',
  CHECKLIST_CHANGE_CATEGORY = 'CHECKLIST_CHANGE_CATEGORY',
  PARTY_SWAP = 'PARTY_SWAP',
  PRIME_COMMIT_CLICK_SUCCESS_TOAST = 'PRIME_COMMIT_CLICK_SUCCESS_TOAST',
  PRIME_COMMIT_COMMITMENT_RESULT = 'PRIME_COMMIT_COMMITMENT_RESULT',
  CHECKLIST_RECATEGORIZE = 'CHECKLIST_RECATEGORIZE',
  CHECKLIST_MISSING_DOCUMENT_CATEGORIZED = 'CHECKLIST_MISSING_DOCUMENT_CATEGORIZED',
  CHECKLIST_DOCUMENT_IMPORTED_TO_CATEGORY = 'CHECKLIST_DOCUMENT_IMPORTED_TO_CATEGORY',
  CHECKLIST_PDF_MERGED = 'CHECKLIST_PDF_MERGED',
  CHECKLIST_PDF_SPLIT = 'CHECKLIST_PDF_SPLIT',
  CHECKLIST_SELECT_PREFERRED_DOCUMENT = 'CHECKLIST_SELECT_PREFERRED_DOCUMENT',
  CHECKLIST_SELECT_UNPREFERRED_DOCUMENT = 'CHECKLIST_SELECT_UNPREFERRED_DOCUMENT',
  CHECKLIST_PDF_EDIT_MODIFIED = 'CHECKLIST_PDF_EDIT_MODIFIED',
  CHECKLIST_TAGS_ASSIGNMENT = 'CHECKLIST_TAGS_ASSIGNMENT'
}

export enum mixpanelPerformanceEvents {
  P_ALL_LOANS = 'P_ALL_LOANS',
  P_SINGLE_LOAN = 'P_SINGLE_LOAN'
}

export enum mixpanelPageNames {
  DOC_CAT = 'Document Review',
  CHECKLIST = 'Checklist',
  READY_FOR_SALE = 'Ready for Sale'
}

// mixpanel will throw an error if token is not set
// https://github.com/mixpanel/mixpanel-js/issues/133#issuecomment-417573211
export const isMixpanelLoaded = () => {
  let result;

  try {
    const mixpanelDistinctId = mixpanel.get_distinct_id();

    result = mixpanelDistinctId ? mixpanel : null;
  } catch (e) {
    result = null;
  }

  return result;
};

// params of track func
type TMixpanelTrackParams = Parameters<typeof track>;

export const mixpanelTrack = (...trackParams: TMixpanelTrackParams) => {
  isMixpanelLoaded()?.track(...trackParams);
};

export const mixpanelTime = (eventName: string) => {
  isMixpanelLoaded()?.time_event(eventName);
};

export const mixpanelTrackPageView = () => {
  isMixpanelLoaded()?.track_pageview();
};

export const shouldMixPanelBeEnabled = () => {
  return process.env.NODE_ENV === 'production';
};
